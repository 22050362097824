export const ALL_EMOJIS = [
    "🤴",
    "👸",
    "✋",
    "👌",
    "👍",
    "🖖",
    "🤘",
    "🕶",
    "😀",
    "😁",
    "😂",
    "🤣",
    "😃",
    "😄",
    "😅",
    "😆",
    "😉",
    "😊",
    "😋",
    "😎",
    "😍",
    "😘",
    "😙",
    "🤗",
    "🤔",
    "😐",
    "😑",
    "😌",
    "😛",
    "😜",
    "🤤",
    "😒",
    "😓",
    "😔",
    "😕",
    "🙃",
    "🤑",
    "🙁",
    "😖",
    "😞",
    "😟",
    "😤",
    "😢",
    "😭",
    "😦",
    "😨",
    "😩",
    "😬",
    "😰",
    "😱",
    "😳",
    "😵",
    "😡",
    "😠",
    "😈",
    "👿",
    "🤡",
    "👹",
    "💀",
    "👻",
    "👽",
    "👾",
    "🤖",
    "💩",
    "🙈",
    "🙉",
    "🙊",
    "💐",
    "🌸",
    "🌲",
    "🌴",
    "🌵",
    "🌿",
    "🍀",
    "🍁",
    "🍇",
    "🍉",
    "🍌",
    "🍍",
    "🍏",
    "🍑",
    "🍒",
    "🍓",
    "🥝",
    "🍱",
    "🍙",
    "🍚",
    "🍜",
    "🍝",
    "🍠",
    "🍣",
    "🍤",
    "🍥",
    "🍦",
    "🍩",
    "🍪",
    "🎂",
    "🍰",
    "🍫",
    "🍬",
    "🍭",
    "🍾",
    "🍷",
    "🍺",
    "🍻",
    "🥂",
    "🥃",
    "🍽",
    "🏺",
    "🌍",
    "🌋",
    "🗻",
    "🏛",
    "🏗",
    "🏡",
    "🏢",
    "🏦",
    "🏩",
    "🏯",
    "🏰",
    "🗼",
    "🗽",
    "🌁",
    "🛩",
    "🚁",
    "🛰",
    "🚀",
    "🌑",
    "🌒",
    "🌕",
    "🌙",
    "🌚",
    "🌛",
    "🌡",
    "🌝",
    "⭐",
    "🌩",
    "🌪",
    "🌀",
    "🌈",
    "⛱",
    "⛄",
    "🔥",
    "💧",
    "🌊",
    "🏆",
    "🏅",
    "🎭",
    "🖼",
    "🎨",
    "🕹",
    "🎵",
    "🔊",
    "🎷",
    "🎸",
    "🎹",
    "💰",
    "💸",
    "🔨",
    "💊",
    "🔶",
    "🔷",
    "🎉",
    "🖤",
    "🔪",
    "💎",
    "😶",
    "🙄",
    "😏",
    "😣",
    "😥",
    "🤐",
    "🌃",
    "🏙",
    "🌄",
    "🌅",
    "🌆",
    "🌇",
    "🌉",
    "🌌",
    "😯",
    "😪",
    "😫",
    "😴",
    "⏳",
    "🔑",
    "🔫",
    "💣",
    "💡",
    "🎁",
    "🎈",
    "🚨",
    "🚘",
    "⛽",
    "🗞"
]

export const ALL_COLORS = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffc107",
    "#ffeb3b",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b"
]
