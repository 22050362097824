import React, { FunctionComponent, useEffect, useState } from "react"
import { CgSpinnerTwoAlt } from "react-icons/cg"

import { getEnojiCustomData } from "web3/getEnojiCustomData"

const useCustomDataLoader = (tokenId: number) => {
    const [customData, setCustomData] = useState<string | null>()
    useEffect(() => {
        const load = async () => {
            let customData = await getEnojiCustomData(tokenId)
            if (customData?.length === 0) {
                customData = null
            }
            setCustomData(customData)
        }
        load()
    }, [tokenId])
    return customData
}

const EnojiRedirection: FunctionComponent<{ id: number }> = ({ id }) => {
    const customData = useCustomDataLoader(id)
    useEffect(() => {
        if (!!customData) {
            window.location.href = customData
        }
    }, [customData])
    return (
        <div className="flex flex-col w-full space-y-2 text-gray-600">
            {customData === undefined && (
                <span className="flex flex-row items-center">
                    Loading Enoji custom data...&nbsp;
                    <CgSpinnerTwoAlt className="animate-spin" />
                </span>
            )}
            {customData === null && (
                <span>
                    Couldn't load Enoji custom data. Check it has been set
                    correctly.
                </span>
            )}
            {!!customData && (
                <>
                    <span className="flex flex-row items-center">
                        Redirecting to {customData}...&nbsp;
                        <CgSpinnerTwoAlt className="animate-spin" />
                    </span>
                    <span>
                        <a
                            href={customData}
                            className="text-gray-200 transition duration-200 hover:opacity-50"
                        >
                            Click here
                        </a>{" "}
                        if it does not redirect automatically.
                    </span>
                </>
            )}
        </div>
    )
}

export default EnojiRedirection
