import React, { FunctionComponent, useEffect } from "react"
import { useDebounce } from "use-debounce"

import { useStoreActions, useStoreState } from "state/hooks"

import Enoji from "./Enoji"

const useOwnersLoader = (tokensIds: number[]) => {
    const enojisOwners = useStoreState((state) => state.enojisOwners)
    const loadEnojisOwners = useStoreActions(
        (actions) => actions.loadEnojisOwners
    )
    const [debouncedTokensIds] = useDebounce(tokensIds, 500)
    useEffect(() => {
        const missingOwners = debouncedTokensIds.filter(
            (tokenId) => enojisOwners[tokenId] === undefined
        )
        if (missingOwners.length > 0) {
            loadEnojisOwners(missingOwners)
        }
    }, [debouncedTokensIds, enojisOwners, loadEnojisOwners])
}

const EnojisList: FunctionComponent<{
    tokensIds: number[]
}> = ({ tokensIds }) => {
    useOwnersLoader(tokensIds)
    return (
        <div className="grid w-full grid-cols-1 gap-4 sm:gap-8 sm:grid-cols-2 md:grid-cols-3 md:gap-16">
            {tokensIds.map((id) => (
                <Enoji key={id} id={id} />
            ))}
        </div>
    )
}

export default EnojisList
