import { providers } from "@0xsequence/multicall"
import { ethers } from "ethers"

import { Enoji__factory } from "data/Enoji__factory"

export const ENOJI_MAINNET_ADDRESS =
    "0x7bc64f0b1e8654ddb350a5ab1e412cc4812f3c2f"

export const getEnojisOwners = async (tokensIds: number[]) => {
    const provider = new providers.MulticallProvider(
        new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_JSON_RPC_ENDPOINT
        )
    )
    return await Promise.all(
        tokensIds.map((tokenId) =>
            Enoji__factory.connect(ENOJI_MAINNET_ADDRESS, provider)
                .ownerOf(tokenId)
                .catch(() => null)
        )
    )
}
