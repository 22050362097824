import React, { useEffect, useMemo, useRef, useState } from "react"

import { useOnScreen } from "hooks/useOnScreen"

import EnojisList from "./EnojiList"
import Input from "./Input"

const ELEMENTS_PER_BATCH = 6 * 3
const ALL_TOKENS_IDS = Array.from(Array(8888 + 1).keys())

const EnojisListWithSearchBar = () => {
    const [searchInput, setSearchInput] = useState<number>()
    const [elementsCount, setElementsCount] = useState(ELEMENTS_PER_BATCH)
    const allTokensIds = useMemo(
        () =>
            searchInput
                ? ALL_TOKENS_IDS.filter((n) =>
                      n.toString().startsWith(searchInput.toString())
                  )
                : ALL_TOKENS_IDS,
        [searchInput]
    )
    const nextPage = useMemo(
        () => () =>
            setElementsCount(
                Math.min(
                    elementsCount + ELEMENTS_PER_BATCH,
                    allTokensIds.length
                )
            ),
        [elementsCount, allTokensIds]
    )
    const hasMore = useMemo(
        () => elementsCount < allTokensIds.length,
        [elementsCount, allTokensIds]
    )

    const loadMoreRef = useRef<HTMLSpanElement>(null)
    const loadMore = useOnScreen(loadMoreRef, "-64px")
    useEffect(() => {
        if (loadMore) {
            nextPage()
        }
    }, [loadMore, nextPage])
    return (
        <div className="flex flex-col px-8 space-y-16 lg:px-0">
            <div className="flex flex-col w-full mx-auto space-y-4 sm:max-w-md">
                <span className="text-center text-gray-400 font-avenir">
                    Search for a specific N, or navigate through the gallery.
                </span>
                <Input
                    colorClassName="bg-black bg-opacity-50"
                    containerColorClassName="bg-opacity-5 bg-parallel-100 border-parallel-100"
                    label="#N"
                    type="number"
                    placeholder="1234"
                    value={searchInput}
                    onChange={(e) => {
                        setElementsCount(ELEMENTS_PER_BATCH) // back to first page
                        setSearchInput((e.target as any).value)
                    }}
                />
            </div>
            <div className="flex flex-col space-y-8">
                <EnojisList tokensIds={allTokensIds.slice(0, elementsCount)} />
                {hasMore && (
                    <span
                        className="text-center text-gray-600"
                        ref={loadMoreRef}
                    >
                        Scroll down to load more (
                        {allTokensIds.length - elementsCount})
                    </span>
                )}
            </div>
        </div>
    )
}

export default EnojisListWithSearchBar
