import classNames from "classnames"
import { FunctionComponent } from "react"

const InputContainer: FunctionComponent<{
    label: string
    className?: string
    colorClassName?: string
}> = ({
    label,
    children,
    className,
    colorClassName = "bg-gray-700 border-gray-200"
}) => (
    <div
        className={classNames(
            "relative z-10 flex flex-row items-center pl-4 space-x-4 font-bold uppercase border-l-2 rounded-sm shadow-md",
            colorClassName,
            className
        )}
    >
        <span className="w-24 text-xs text-gray-300 text-opacity-50">
            {label}
        </span>
        {children}
    </div>
)

export default InputContainer
