import classNames from "classnames"
import React, { FunctionComponent } from "react"

import { ALL_COLORS, ALL_EMOJIS } from "data/data"
import * as enojis from "data/enojis.json"

const generateEnojiSvg = (emojis: string[], colors: string[]) =>
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="512px" font-size="64px">
<defs><linearGradient x1="0" y1="0" x2="100%" y2="100%" id="gradient"><stop stop-color="${
        colors[0]
    }" offset="0%" /><stop stop-color="${
        colors[1]
    }" offset="100%" /></linearGradient></defs>
<rect width="100%" height="100%" fill="url(#gradient)" />
<text text-anchor="middle" x="50%" y="50%" alignment-baseline="middle" font-family="sans-serif" fill="white">( ${emojis.join(
        " , "
    )} )</text></svg>`

const EnojiSVG: FunctionComponent<{ id: number }> = ({ id }) => {
    const colors = enojis.colors[id].map((e) => ALL_COLORS[e])
    const emojis = enojis.emojis[id].map((e) => ALL_EMOJIS[e])
    const svg = generateEnojiSvg(emojis, colors)
    return (
        <img
            alt="content"
            src={`data:image/svg+xml;base64,${btoa(
                unescape(encodeURIComponent(svg))
            )}`}
            className={classNames("w-auto rounded-sm shadow-md")}
        />
    )
}

export default EnojiSVG
