import { StoreProvider } from "easy-peasy"
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import EnojiDetailsPage from "routes/EnojiDetailsPage"
import HomePage from "routes/HomePage"

import store from "state/store"

import GlobalModal from "components/GlobalModal"

const App = () => {
    return (
        <Router>
            <StoreProvider store={store}>
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route
                        path="/:idOrEmojis"
                        exact
                        component={EnojiDetailsPage}
                    />
                </Switch>
                <ToastContainer />
                <GlobalModal />
            </StoreProvider>
        </Router>
    )
}

export default App
