import React, { useMemo } from "react"
import { Redirect, useParams } from "react-router"

import { ALL_EMOJIS } from "data/data"
import * as enojis from "data/enojis.json"

import EnojiRedirection from "components/EnojiRedirection"
import EnojiSVG from "components/EnojiSVG"
import MinimalHero from "components/MinimalHero"

const EnojiDetailsPage = () => {
    const { idOrEmojis } = useParams<{ idOrEmojis: string }>()
    const tokenId = useMemo(() => {
        try {
            try {
                // try parsing tokenId as number
                const tokenId = parseInt(idOrEmojis)
                if (isNaN(tokenId)) throw new Error()
                if (tokenId > enojis.colors.length) {
                    return null
                } else {
                    return tokenId
                }
            } catch (e) {
                // could be formatted as (1,2) emojis
                const tokenId = enojis.emojis.findIndex(
                    ([emojiIndex1, emojiIndex2], i) => {
                        const string = `(${ALL_EMOJIS[emojiIndex1]},${ALL_EMOJIS[emojiIndex2]})`
                        return string === idOrEmojis
                    }
                )
                if (tokenId < 0) {
                    return null
                } else {
                    return tokenId
                }
            }
        } catch (e) {
            return null
        }
    }, [idOrEmojis])
    return tokenId === null ? (
        <Redirect to="/" />
    ) : (
        <>
            <div className="flex flex-col items-center h-full min-h-screen bg-black font-avenir">
                <MinimalHero />
                <div className="relative z-10 w-64 mx-auto my-8 overflow-hidden">
                    <span className="absolute top-0 left-0 z-10 mt-2 ml-3 font-sans font-bold text-white">
                        #{tokenId}
                    </span>
                    <EnojiSVG id={tokenId} />
                </div>
                <div className="px-2 mx-auto text-center">
                    <EnojiRedirection id={tokenId} />
                </div>
            </div>
        </>
    )
}

export default EnojiDetailsPage
