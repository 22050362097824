import React from "react"

import { useStoreActions, useStoreState } from "state/hooks"

import Modal from "./Modal"

const GlobalModal = () => {
    const modalOpen = useStoreState((state) => state.modal.isOpen)
    const modalContent = useStoreState((state) => state.modal.content)
    const closeModal = useStoreActions((actions) => actions.modal.close)
    return modalOpen ? (
        <Modal
            isOpen={modalOpen}
            title={modalContent?.title}
            onRequestClose={() => closeModal()}
        >
            {modalContent?.children}
        </Modal>
    ) : null
}

export default GlobalModal
