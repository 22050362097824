import classNames from "classnames"
import { ethers } from "ethers"
import React, { FunctionComponent, useMemo, useState } from "react"
import { FiCopy } from "react-icons/fi"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { toast } from "react-toastify"

import { ENOJI_DATA_REGISTRY_MAINNET } from "web3/getEnojiCustomData"

import { EnojiDataRegistry__factory } from "data/EnojiDataRegistry__factory"
import { ALL_EMOJIS } from "data/data"
import * as enojis from "data/enojis.json"

import { formatAddress } from "./Enoji"
import EnojiSVG from "./EnojiSVG"
import Input from "./Input"

const CUSTOM_DATA_PREFIX = "https://"

const setEnojiData = async (tokenId: number, data: string) => {
    await (window as any).ethereum.enable()
    const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum,
        "mainnet"
    )
    await EnojiDataRegistry__factory.connect(
        ENOJI_DATA_REGISTRY_MAINNET,
        provider.getUncheckedSigner()
    )
        .setData(tokenId, data)
        .then((tx) =>
            toast.info(`Submitted transaction ${formatAddress(tx.hash)}`)
        )
        .catch((e) => toast.error((e.message || e).toString()))
}

const EnojiSettings: FunctionComponent<{ id: number }> = ({ id }) => {
    const emojis = enojis.emojis[id].map((e) => ALL_EMOJIS[e])
    const [customData, _setCustomData] = useState("")
    const customDataWithoutHttps = useMemo(() => {
        if (customData.startsWith(CUSTOM_DATA_PREFIX)) {
            return customData.slice(CUSTOM_DATA_PREFIX.length)
        } else {
            return customData
        }
    }, [customData])
    const setCustomData = (data: string) =>
        _setCustomData(CUSTOM_DATA_PREFIX + data)

    const [copied, setCopied] = useState(false)
    const copyLink = async () => {
        await navigator.clipboard.writeText(
            `https://enojis.xyz/(${emojis[0]},${emojis[1]})`
        )
        setCopied(true)
        await new Promise((resolve) => setTimeout(resolve, 1000))
        setCopied(false)
    }

    return (
        <div className="flex flex-col items-center w-full space-y-8 text-sm text-gray-200">
            <div className="flex flex-row w-full space-x-4">
                <div className="w-96">
                    <EnojiSVG id={id} />
                </div>
                <div className="flex flex-col space-y-4">
                    <span>
                        If you own this Enoji, you can specify a custom link to
                        redirect to.
                    </span>
                    <span>
                        <b className="text-red-400">Warning:</b> you <b>must</b>{" "}
                        own this Enoji for the transaction to succeed. Do not
                        attempt otherwise.
                    </span>
                </div>
            </div>

            <div className="flex flex-col space-y-1">
                <span className="text-center text-gray-600">
                    Your link will be available on
                </span>
                <div className="flex flex-row items-center">
                    <a
                        href={`https://enojis.xyz/(${emojis[0]},${emojis[1]})`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl font-bold text-gray-200"
                    >
                        enojis.xyz/({emojis[0]},{emojis[1]})
                    </a>
                    &nbsp;
                    <button
                        onClick={copyLink}
                        className="inline-flex flex-row items-center text-gray-600 transition duration-300 hover:opacity-50"
                    >
                        {copied ? (
                            <>
                                <IoMdCheckmarkCircleOutline />
                                &nbsp;Copied
                            </>
                        ) : (
                            <>
                                <FiCopy />
                                &nbsp;Copy
                            </>
                        )}
                    </button>
                </div>
            </div>
            <div className="flex flex-col w-full text-center">
                <Input
                    className="relative z-10 shadow-md"
                    type="text"
                    label="https://"
                    placeholder="twitter.com/enoji_project"
                    onChange={(e) => setCustomData((e.target as any).value)}
                    value={customDataWithoutHttps}
                />
                <button
                    onClick={() => setEnojiData(id, customData)}
                    className={classNames(
                        "group relative flex flex-row items-center justify-center px-8 pb-3 pt-4 font-bold",
                        "text-center text-gray-400 -mt-1 bg-black bg-opacity-60 border-b-2 rounded-b-sm font-avenir",
                        "text-sm transition duration-200 hover:text-gray-900 overflow-hidden",
                        "border-green-400"
                    )}
                >
                    <span className="relative z-10">Set Custom Data</span>
                    <div
                        className={classNames(
                            "absolute bottom-0 left-0 w-full h-full transition duration-200 origin-bottom",
                            "transform scale-y-0 group-hover:scale-y-100",
                            "bg-green-400"
                        )}
                    />
                </button>
            </div>
        </div>
    )
}

export default EnojiSettings
