import React from "react"

import Divider from "components/Divider"
import EnojisListWithSearchBar from "components/EnojisListWithSearchBar"
import Hero from "components/Hero"

const HomePage = () => (
    <>
        <div className="flex flex-col w-full font-avenir">
            <Hero />
            <Divider />
        </div>
        <div className="flex flex-col items-center w-full max-w-4xl pt-16 pb-32 mx-auto font-avenir">
            <EnojisListWithSearchBar />
        </div>
    </>
)

export default HomePage
