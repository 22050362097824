import logo from "images/logo.png"
import React from "react"

const MinimalHero = () => (
    <div className="flex flex-col items-center justify-center w-full pt-20 text-white bg-black">
        <img className="w-64" src={logo} alt="logo" />
    </div>
)

export default MinimalHero
