import logo from "images/logo.png"
import React, { useCallback, useEffect, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import { CSSTransition, SwitchTransition } from "react-transition-group"

const EMOJIS = [
    ["😜", "😍", "🙊", "😂"],
    ["✌️", "👋", "🎉", "👍"]
]

const Hero = () => {
    const [emojisIndices, setEmojisIndices] = useState([0, 0])
    const [nextEmojiIndex, setNextEmojiIndex] = useState(0)
    const updateEmoji = useCallback(
        (i: number) => {
            const newEmojisIndices = [...emojisIndices]
            newEmojisIndices[i] = (newEmojisIndices[i] + 1) % EMOJIS[i].length
            setEmojisIndices(newEmojisIndices)
            setNextEmojiIndex(1 - nextEmojiIndex)
        },
        [nextEmojiIndex, emojisIndices]
    )
    useEffect(() => {
        const interval = setInterval(() => updateEmoji(nextEmojiIndex), 1000)

        return () => {
            clearInterval(interval)
        }
    })

    const Emoji = (i: number) => {
        const emoji = EMOJIS[i][emojisIndices[i]]
        return (
            <span className="relative inline-flex align-bottom text-primary-100">
                <SwitchTransition mode="in-out">
                    <CSSTransition
                        key={emoji}
                        classNames={{
                            enter: "opacity-0",
                            enterActive: "mb-8 opacity-0",
                            exitActive: "-mb-8 opacity-0",
                            exitDone: "opacity-0"
                        }}
                        addEndListener={(node, done) =>
                            node.addEventListener("transitionend", done, false)
                        }
                    >
                        <span className="absolute bottom-0 left-0 text-6xl transition-all duration-500 transform">
                            {emoji}
                        </span>
                    </CSSTransition>
                </SwitchTransition>
                <span className="invisible opacity-0">😜</span>
            </span>
        )
    }

    return (
        <div className="flex flex-col items-center justify-center w-full pt-20 text-white bg-black">
            <img className="w-64" src={logo} alt="logo" />
            <span className="my-20 font-sans text-6xl">
                (&nbsp;&nbsp;
                {Emoji(0)}
                &nbsp;&nbsp;, &nbsp;&nbsp;
                {Emoji(1)}
                &nbsp;&nbsp;)
            </span>
            <span className="max-w-sm font-bold text-center text-gray-600 font-avenir">
                A collection of 8'888 emoji combinations living on the
                blockchain, based on{" "}
                <a
                    className="text-white transition duration-200 hover:opacity-50"
                    href="https://twitter.com/the_n_project_"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    The N Project
                </a>
            </span>
            <BiChevronDown className="mx-auto mt-16 text-6xl animate-bounce" />
        </div>
    )
}

export default Hero
