import { providers } from "@0xsequence/multicall"
import { ethers } from "ethers"

import { EnojiDataRegistry__factory } from "data/EnojiDataRegistry__factory"

export const ENOJI_DATA_REGISTRY_MAINNET =
    "0x22c4871d27b9821cbdabde2e38ab28fd6ebd0dca"

export const getEnojiCustomData = async (tokenId: number) => {
    const provider = new providers.MulticallProvider(
        new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_JSON_RPC_ENDPOINT
        )
    )
    return await EnojiDataRegistry__factory.connect(
        ENOJI_DATA_REGISTRY_MAINNET,
        provider
    )
        .getData(tokenId)
        .catch(() => null)
}
