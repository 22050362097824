import classnames from "classnames"
import React, { FunctionComponent } from "react"
import { BiX } from "react-icons/bi"
import ReactModal, { Props } from "react-modal"

const Modal: FunctionComponent<Props & { title?: string }> = ({
    onRequestClose,
    children,
    title,
    ...props
}) => (
    <ReactModal
        overlayClassName={classnames(
            "fixed inset-0 z-50",
            "flex items-center justify-center",
            "bg-black bg-opacity-50"
        )}
        className="w-full mx-2 sm:max-w-lg"
        shouldFocusAfterRender={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={onRequestClose}
        {...props}
    >
        <div className="w-full bg-gray-800 rounded-sm">
            <div
                className={classnames(
                    "flex flex-row w-full",
                    "p-6",
                    "text-gray-200"
                )}
            >
                {title && (
                    <span className="font-bold text-gray-400">{title}</span>
                )}
                <button
                    className="p-2 ml-auto -mt-2 -mr-2 text-2xl text-gray-400 transition duration-200 transform hover:text-gray-200"
                    onClick={onRequestClose}
                >
                    <BiX />
                </button>
            </div>
            <div className={classnames("p-6 pt-0")}>{children}</div>
        </div>
    </ReactModal>
)

export default Modal
