import { useState, useEffect, RefObject } from "react"

export const useOnScreen = (ref: RefObject<any>, rootMargin = "0px") => {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false)
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting)
            },
            {
                rootMargin
            }
        )
        if (ref.current) {
            observer.observe(ref.current)
        }
        const current = ref.current
        return () => {
            observer.unobserve(current)
        }
    }, []) //eslint-disable-line
    return isIntersecting
}
