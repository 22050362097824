import { action, Action, thunk, Thunk } from "easy-peasy"

import { getEnojisOwners } from "web3/getEnojisOwners"

import modalModel, { ModalModel } from "./ModalModel"

export interface StoreModel {
    enojisOwners: Record<number, string | null | undefined>
    setEnojisOwners: Action<
        StoreModel,
        Record<number, string | null | undefined>
    >
    loadEnojisOwners: Thunk<StoreModel, number[]>

    modal: ModalModel
}

const storeModel: StoreModel = {
    enojisOwners: {},
    setEnojisOwners: action((state, payload) => {
        state.enojisOwners = { ...state.enojisOwners, ...payload }
    }),
    loadEnojisOwners: thunk(async (actions, payload) => {
        const owners = await getEnojisOwners(payload)
        const ownerByTokenId: Record<number, string | null> = {}
        owners.forEach((owner, i) => {
            ownerByTokenId[payload[i]] = owner
        })
        actions.setEnojisOwners(ownerByTokenId)
    }),

    modal: modalModel
}

export default storeModel
