import { parseEther } from "@ethersproject/units"
import classNames from "classnames"
import { ethers } from "ethers"
import React, { FunctionComponent, useCallback } from "react"
import { BsGearFill } from "react-icons/bs"
import { CgSpinnerTwoAlt } from "react-icons/cg"
import { toast } from "react-toastify"
import styled, { keyframes } from "styled-components"

import { useStoreActions, useStoreState } from "state/hooks"

import { ENOJI_MAINNET_ADDRESS } from "web3/getEnojisOwners"

import { Enoji__factory } from "data/Enoji__factory"

import EnojiSVG from "./EnojiSVG"
import EnojiSettings from "./EnojiSettings"

const AppearButton = styled.button`
    animation: ${keyframes`
        from { transform: translateY(-100%); }
        to { transform: translateY(0); }
    `} 0.2s ease-in-out forwards;
`
const AppearA = styled.a`
    animation: ${keyframes`
        from { transform: translateY(-100%); }
        to { transform: translateY(0); }
    `} 0.2s ease-in-out forwards;
`

export const formatAddress = (address: string) =>
    `0x${address.slice(2, 6)}...${address.slice(-4)}`

const mintEnoji = async (tokenId: number) => {
    await (window as any).ethereum.enable()
    const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum,
        "mainnet"
    )
    await Enoji__factory.connect(
        ENOJI_MAINNET_ADDRESS,
        provider.getUncheckedSigner()
    )
        .mint(tokenId, { value: parseEther("0.01") })
        .catch((e) => toast.error((e.message || e).toString()))
}

const Enoji: FunctionComponent<{
    id: number
}> = ({ id }) => {
    const owner = useStoreState((state) => state.enojisOwners[id])
    const ButtonOrA = (owner === null ? AppearButton : AppearA) as any

    const openModal = useStoreActions((actions) => actions.modal.show)
    const openSettingsModal = useCallback(() => {
        openModal({
            title: `Edit Enoji #${id}`,
            children: <EnojiSettings id={id} />
        })
    }, [openModal, id])

    return (
        <div className="relative flex flex-col">
            <div className="relative z-10 overflow-hidden">
                <span className="absolute top-0 left-0 z-10 mt-2 ml-3 font-sans font-bold text-white">
                    #{id}
                </span>
                <EnojiSVG id={id} />
                <div
                    className={classNames(
                        "absolute bg-black opacity-0 top-0 left-0 w-full h-full rounded-sm transition duration-200",
                        !owner && "opacity-50"
                    )}
                />
                <span
                    className={classNames(
                        "absolute right-0 top-0 mt-2 mr-2 duration-200 transition",
                        owner !== undefined && "opacity-0 invisible"
                    )}
                >
                    <CgSpinnerTwoAlt className="text-3xl text-white opacity-25 animate-spin" />
                </span>
                <button
                    className={classNames(
                        "absolute right-0 top-0 mt-2 mr-2 transition duration-200 p-1 group",
                        !owner && "opacity-0 invisible"
                    )}
                    onClick={openSettingsModal}
                >
                    <BsGearFill className="text-2xl text-white transition duration-200 transform opacity-50 group-hover:opacity-100 group-hover:rotate-45" />
                </button>
            </div>
            <ButtonOrA
                className={classNames(
                    "group relative flex flex-row items-center justify-center px-8 pb-2 pt-3 font-bold",
                    "text-center text-gray-400 -mt-1 bg-black bg-opacity-25 border-b-2 rounded-sm font-avenir",
                    "text-sm transition duration-200 hover:text-gray-900",
                    owner === null ? "border-green-400" : "border-gray-400",
                    owner === undefined && "invisible"
                )}
                {...(owner === null
                    ? {
                          onClick: () => mintEnoji(id)
                      }
                    : {
                          href: `https://opensea.io/assets/${ENOJI_MAINNET_ADDRESS}/${id}`,
                          target: "_blank",
                          rel: "noopener noreferrer"
                      })}
            >
                <span className="relative z-10">
                    {owner ? `Owned by ${formatAddress(owner)}` : "Mint"}
                </span>
                <div
                    className={classNames(
                        "absolute bottom-0 left-0 w-full h-full transition duration-200 origin-bottom",
                        "transform scale-y-0 group-hover:scale-y-100",
                        owner === null ? "bg-green-400" : "bg-gray-400"
                    )}
                />
            </ButtonOrA>
        </div>
    )
}

export default Enoji
